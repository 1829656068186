//Core
import React from "react";
//Styles
import { ImgBox, SectionContainer } from "../../../../styles/components/Layout";
import { SecondaryButton } from "../../../../styles/components/buttons";
import Grid2 from "@mui/material/Unstable_Grid2";
import CardsGrid from "../../../templates/cardsGrid/CardsGrid";
import { SecondaryTitle, SectionParagraph } from "../../../../styles/components/typography";
//Images
import accessImg from "./img/access.webp";
import { BulbIcon } from "../../../../assets/svg/icons";
//Data
import { advantagesList } from "./Advantages.data";
//Utils
import { pathWithUtm } from "../../../../utils/pathWithUtm";

const Advantages = () => {
  const renderImage = (className) => (
    <Grid2 xs={12} md={5} className={className}>
      <ImgBox sx={{transform: { xs: 'none', br480: 'scale(0.85)',  md: "scale(1.15)" }, mb: {xs:'5rem', md: 0}}}>
        <img src={accessImg} alt={"access"} />
      </ImgBox>
    </Grid2>
  );

  return (
    <SectionContainer pb={'17.5rem'}>
      <Grid2 container m={"0 auto 21rem"} columnSpacing={{ xs: 0, md: "10rem" }}>
        <Grid2 xs={12} sm={10} md={7} m={"0 auto"} sx={{ pl: 0 }}>
          <SecondaryTitle>
            Easier access to better data. <br /> That’s the future of web3 advertising
          </SecondaryTitle>
          <SectionParagraph>
            Data has become central to modern marketing. Yet for marketers advertising in web3, data has been a major pain point.
            Advertisers haven’t been able to access the same powerful data that was standard on web2.
          </SectionParagraph>
          <br />
          {renderImage("mobileOnly")}

          <SectionParagraph>
            <b>At least, until now.</b>{" "}
          </SectionParagraph>
          <br />
          <SectionParagraph>
            Web3m gives advertisers the customer data you need to outperform. Create tailored ads based on demographic, psychographic, and
            transactional data
          </SectionParagraph>
        </Grid2>
        {renderImage("desktopOnly")}
      </Grid2>

      <SecondaryTitle m={{ xs: "0 auto 7.7rem", md: "0 0 7.7rem" }} maxWidth={"60rem"}>
        Advertising in web3 just got easier—and way more powerful{" "}
      </SecondaryTitle>
      <CardsGrid data={advantagesList} titleWidth={{ xs: '80%', br480: "60%" }} />

      <a href={pathWithUtm("https://blog.web3m.io/")} target={"blog"} style={{display: 'block', marginTop: '-1rem'}}>
        <SecondaryButton startIcon={<BulbIcon />}>Learn more</SecondaryButton>
      </a>
    </SectionContainer>
  );
};

export default Advantages;
