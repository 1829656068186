import { Box, Stack, styled } from "@mui/material";
import { SecondaryButton } from "../../../../styles/components/buttons";
import { mobileScreen, smallMobileScreen, tabletOnlyScreen } from "../../../../utils/themeBreakpoints";

export const MediaBackground = styled(Box)`
  background-color: #191a24;
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const MediaCard = styled(Stack)`
  height: 100%;

  ${smallMobileScreen} {
    margin-bottom: 3rem;
  }

  > div:first-of-type {
    img {
      border-radius: 1rem;
      filter: grayscale(100%);
      height: 35rem;
      object-fit: cover;

      ${tabletOnlyScreen} {
        width: auto;
        display: block;
        margin: 0 auto;
      }

      ${smallMobileScreen} {
        display: block;
        width: 80%;
        margin: 0 auto;
      }
    }
  }

  p {
    width: 90%;
    margin-bottom: 3rem;
  }

  a {
    margin-top: auto;
    color: #fff;
    opacity: 0.68;
    width: fit-content;
    transition: 0.3s;
    text-decoration: underline;

    > svg {
      margin-left: 1rem;
      vertical-align: middle;
    }

    &:hover {
      opacity: 0.9;
    }
  }

  ${mobileScreen} {
    h3,
    p {
      text-align: center;
    }

    p {
      width: 80%;
      margin: 0 auto 4rem;
    }

    a {
      margin: 0 auto;
    }
  }
`;

export const SeeMoreButton = styled(SecondaryButton)`
  margin-top: 10rem;
  background-color: transparent;
  color: #e7526a;
  border-color: #e7526a;
  text-decoration: none;
  
  &:hover{
    background-color: transparent;
  }

  ${mobileScreen} {
    width: 100%;
    max-width: 100%;
    margin-top: 10rem;
  }
`;
