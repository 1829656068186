import { PixelBackground, PixelContent, PixelImageBox, PixelTextBlock } from "./Pixel.styles";
import { RelativeBox, SectionContainer } from "../../../../styles/components/Layout";
import Grid2 from "@mui/material/Unstable_Grid2";
import { List, ListItem, ListItemIcon, Typography } from "@mui/material";
import { pixelList } from "./Pixel.data";
import airdropImg from "./img/airdrop.webp";

const Pixel = () => {
  return (
    <RelativeBox pb={"20rem"}>
      <PixelBackground />
      <PixelContent>
        <SectionContainer>
          <Grid2 container>
            <Grid2 xs={12} md={6}>
              <PixelTextBlock>
                <Typography variant={"h1"}>Don’t miss the <br/> community airdrop</Typography>
                <Typography variant={"h2"}>Install the pixel. Get rewarded.</Typography>
                <Typography>
                  Receive the Web3m airdrop in Q4 2023. All you have to do is implement the Web3m pixel on your web3 site.
                </Typography>
                <List sx={{ mb: "14rem" }}>
                  {pixelList?.map((el, i) => (
                    <ListItem key={i}>
                      <ListItemIcon>{el.icon}</ListItemIcon>
                      {el.text}
                    </ListItem>
                  ))}
                </List>
              </PixelTextBlock>
            </Grid2>
            <Grid2 md={6}>
              <PixelImageBox>
                <img src={airdropImg} alt={"image"} />
              </PixelImageBox>
            </Grid2>
          </Grid2>
        </SectionContainer>
      </PixelContent>
    </RelativeBox>
  );
};

export default Pixel;
