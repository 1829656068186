import { Box, Stack, styled } from "@mui/material";
import { ImgBox, SectionBackground } from "../../../../styles/components/Layout";
import { mobileScreen, smallMobileScreen, tabletOnlyScreen } from "../../../../utils/themeBreakpoints";

export const PixelBackground = styled(SectionBackground)`
  background-color: #191a24;
`;

export const PixelContent = styled(Box)`
  position: relative;
  max-width: 176rem;
  width: 95%;
  margin: 0 auto;
  padding: 14rem 0;

  border-radius: 3rem;
  background-image: linear-gradient(252deg, #66caff, #369afe);

  ${mobileScreen} {
    padding: 7rem 0 0;
  }

  ${tabletOnlyScreen} {
    width: 80%;
  }
`;

export const PixelTextBlock = styled(Stack)`
  > h1 {
    margin-bottom: 1.5rem;
  }

  > h2,
  > p {
    color: #fff;
    margin-bottom: 6rem;
  }

  > p {
    max-width: 42rem;
    line-height: 1.25;

    ${tabletOnlyScreen} {
      max-width: 60rem;
    }
  }

  ul {
    margin-bottom: 0;
  }

  li {
    //margin-left: 0;
    align-items: flex-start;
    padding-left: 0;

    .MuiListItemIcon-root {
      min-width: fit-content;
      margin-right: 1rem;
    }

    svg {
      //height: 2rem;
      margin-top: 1rem;
      width: 2.5rem;
      path {
        fill: #fff;
      }
    }

    .MuiListItemText-primary {
      color: #fff;
      text-transform: uppercase;
      a {
        color: #fff;
        transition: 0.3s;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  ${mobileScreen} {
    h1,
    h2,
    p {
      text-align: center;
    }

    h1 {
      margin-bottom: 3rem;
    }

    p {
      margin: 0 auto 6rem;
    }
  }

  ${smallMobileScreen} {
    h2 {
      margin-bottom: 5rem;
    }
    p {
      margin-bottom: 6.5rem;
    }

    li {
      padding-right: 0;
    }
  }
`;

export const PixelImageBox = styled(ImgBox)`
  ${mobileScreen} {
    overflow: hidden;
    img {
      transform: translateY(5rem);
    }
  }
`;
