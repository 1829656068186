import { MediaBackground, MediaCard, SeeMoreButton } from "./Media.styles";
import { Box, Typography } from "@mui/material";
import { ImgBox, SectionContainer } from "../../../../styles/components/Layout";
import Grid2 from "@mui/material/Unstable_Grid2";
import { mediaList } from "./Media.data";
import { ForwardIcon, TriangleIcon } from "../../../../assets/svg/icons";
import { NotebookIcon } from "../../../../assets/svg/buttonIcons";
import { SecondaryTitle } from "../../../../styles/components/typography";
import { pathWithUtm } from "../../../../utils/pathWithUtm";

const Media = () => {
  return (
    <Box sx={{ position: "relative" }}>
      <MediaBackground />
      <SectionContainer p={"15rem 0"}>
        <SecondaryTitle color={"#fff"} mb={"10rem"}>
          Featured Media
        </SecondaryTitle>
        <Grid2 container spacing={"7.5rem"}>
          {mediaList?.map((el, i) => (
            <Grid2 xs={12} md={4} key={el.title}>
              <MediaCard>
                <ImgBox mb={{ xs: "5rem", br480: '3rem' }}>
                  <img src={el.img} alt={el.title} />
                </ImgBox>
                <Typography variant={"h3"} color={"#fff"} mb={"4rem"}>
                  {el.title}
                </Typography>
                <Typography color={"#fff"}>{el.desc}</Typography>
                <a href={pathWithUtm(el.link)} target={"blog " + i}>
                  <TriangleIcon className={"mobileOnly"} style={{ transform: "rotate(180deg)", marginRight: "1rem" }} />
                  Read the story
                  <ForwardIcon className={"desktopOnly"} />
                  <TriangleIcon className={"mobileOnly"} />
                </a>
              </MediaCard>
            </Grid2>
          ))}
        </Grid2>

        <a href={pathWithUtm("https://blog.web3m.io/")} target="blog" style={{ width: '100%'  }}>
          <SeeMoreButton startIcon={<NotebookIcon />}>See more stories on Web3m’s Blog</SeeMoreButton>
        </a>
      </SectionContainer>
    </Box>
  );
};

export default Media;
