import { useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";

export const useBreakpoints = () => {
    const theme = useTheme();
    const desktopScreen = useMediaQuery(theme.breakpoints.down("xl")); //1536
    const laptopScreen = useMediaQuery(theme.breakpoints.down("lg")); //1200
    const screen1024 = useMediaQuery(theme.breakpoints.down("br1024")); //1024
    const tabletScreen = useMediaQuery(theme.breakpoints.down("md")); //900
    const screen768 = useMediaQuery(theme.breakpoints.down("br768")); //768
    const mobileScreen = useMediaQuery(theme.breakpoints.down("sm")); //600
    const smallMobileScreen = useMediaQuery(theme.breakpoints.down("br480")); //480

    const [desktopOnly, setDesktopOnly] = useState({});
    const [mobileOnly, setMobileOnly] = useState({});

    useEffect(() => {
        console.log();
        if (screen768) {
            setDesktopOnly({ display: "none" });
            setMobileOnly({});
        } else {
            setDesktopOnly({});
            setMobileOnly({ display: "none" });
        }
    }, [screen768]);

    return {
        desktopScreen,
        laptopScreen,
        screen1024,
        tabletScreen,
        screen768,
        mobileScreen,
        desktopOnly,
        mobileOnly,
        smallMobileScreen
    };
};
