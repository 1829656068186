import {createTheme} from "@mui/material";
import version from '../version.json';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            br480: 480,
            sm: 600,
            br768: 768,
            md: 900,
            br1024: 1024,
            lg: 1200,
            xl: 1536,
        }
    },
    palette: {
        primary: {
            // light: "#4540BB", //positive numbers
            main: "#1B418E", //buttons get early access
            dark: "#132338", //text
        },
        secondary: {
            // light: "#C1C9D1", //grey
            main: "#195AFE", //layout buttons
            dark: "#00213A", //header
        },
        info: {
            light: '#F4EDE4', //background
            main: "#F2A626", //buttons niches
        },
        error: {
            main: "#FF0000",
        },
        success: {
            main: '#04B78A' //expand button
        }
    },
    typography: {
        fontSize: 14,
        fontWeightRegular: 400,
        fontWeightBold: 600,
        fontFamily: "'Hellix', sans-serif",
        h1: {
            fontFamily: "'F37 Judge', sans-serif",
            fontWeight: 'bold',
            fontSize: '6.3rem',
            color: '#FFFFFF',
            letterSpacing: '2.2px',
        },
        h2: {
            fontFamily: "'F37 Judge-Thin', 'F37 Judge', sans-serif",
            fontWeight: '300',
            fontSize: "4.6rem",
            letterSpacing: '1.61px',
            color: "#010A10",
        },
        h3: {
            fontSize: "2.6rem",
            fontWeight: 600,
            letterSpacing: "-0.27px",
            color: "#11354F",
            lineHeight: '1.65'
        },
        h4: {//positive numbers
            fontFamily: "'F37Judge', sans-serif",
            fontSize: "4.03rem",
            fontWeight: 'bold',
            lineHeight: '1.2',
            letterSpacing: '1.41px',
            color: '#4540bb',
        },
        h5: {//get early access
            fontSize: '1.82rem',
            fontWeight: 'bold',
            letterSpacing: '0.2px',
            color: '#1b418e',
        },
        subtitle1: {
            fontSize: '2.9rem',
            fontWeight: 'bold',
            lineHeight: 1.07,
            letterSpacing: '1.01px',
            color: '#FFFFFF',
        },
        subtitle2: {
            fontSize: '2rem',
            fontWeight: 'bold',
            lineHeight: 1.25,
            letterSpacing: '-0.21px',
            color: '#11354f',
        },
        body1: {
            fontSize: "2rem",
            lineHeight: 1.65,
            letterSpacing: "-0.21px",
            color: '#11354f',
        },
        body2: {
            fontSize: '2rem',
            // fontWeight: 'bold',
            lineHeight: 1.25,
            letterSpacing: '-0.21px',
        },
        // button: {
        //     fontSize: "1.8rem",
        //     fontWeight: 500,
        //     lineHeight: 1,
        //     letterSpacing: "0.01rem",
        //     textTransform: 'unset'
        // },
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: (theme) => ({
                ':root': {
                    '--product-version': version["product-version"]
                },
                html: {
                    padding: 0,
                    margin: 0,
                    [theme.breakpoints.up('lg')]: {
                        fontSize: 10,
                    },
                },
            }),
        },
    },
})

export default theme;