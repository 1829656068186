import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

export const useAuth = () => {
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();

  // Connected if already authorized in another project
  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      const getToken = async () => {
        try {
          await getAccessTokenSilently();
        } catch (err) {
          console.log(err?.message);
        }
      };
      void getToken();
    }
  }, [isAuthenticated, isLoading, getAccessTokenSilently]);

  return {};
};
