import { GeoLocationIcon, WalletIcon, WorldIcon } from "./img/Hero.icons";


export const heroListData = [
  {
    icon: WalletIcon(),
    text: "BIGGEST CRYPTO CHANNELS FOR PPC ADS IN THE MARKET"
  },
  {
    icon: WorldIcon(),
    text: "AI - ASSISTED CAMPAIGN SET-UP"
  },
  {
    icon: GeoLocationIcon(),
    text: "POWERFUL DASHBOARD FOR SCALING RESULTS AND ANALYTICS"
  },
]