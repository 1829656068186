import { FlexBox, ImgBox, SectionContainer } from "../../../../styles/components/Layout";
import forwhomImg from "./img/forwhom.webp";
import TextImageCard from "../../../templates/textImage/TextImageCard";
import { SecondaryTitle, SectionMainTitle, SectionParagraph } from "../../../../styles/components/typography";

const ForWhom = () => {
  return (
    <SectionContainer p={{ xs: "20rem 0 15rem", br480: "25rem 0 15rem", sm: '30rem 0 15rem', md: "14rem 0" }}>
      <TextImageCard image={forwhomImg} textWidth={7.5}>
        <SectionMainTitle mb={"7.2rem"}>
          Are you a <b> crypto </b> company owner?
        </SectionMainTitle>
        <SecondaryTitle mb={'4rem'}>This tool is created to help you to Increase online sales and brand awareness</SecondaryTitle>
        <SectionParagraph width={'85%'}>
          Set up, go live, and scale your campaigns. Advertise your project across top finance and crypto websites, including
          Cryptopotato.com, Etherscan.io, coingecko.com, and more.
        </SectionParagraph>
      </TextImageCard>
    </SectionContainer>
  );
};

export default ForWhom;
