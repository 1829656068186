import img1 from "../../../../assets/img/cards/loudspeaker.webp";
import img2 from "./img/effective.webp";
import img3 from "./img/analysis.webp";

export const advantagesList = [
  {
    title: "Multichannel platform",
    desc: "Web3m is a decentralized ad platform. It’s never been easier to advertise your project across crypto.",
    img: img1,
  },  {
    title: "Effective web3 data",
    desc: "Access demographic, psychographic, geographic, and wallet-specific data to improve your ad ROI.",
    img: img2,
  },  {
    title: "Robust web3 analytics",
    desc: "Know your customers. It starts with a dashboard that lets you track their real-time activities across your site—and web3.",
    img: img3,
  },
];
