//Core
import React from "react";
//Styles
import { Typography, useMediaQuery } from "@mui/material";
import { SectionContainer } from "../../../../styles/components/Layout";
import {
  JoinDiscordButton,
  ThankYouImageBox,
  ThankYouLowerMobileBackground,
  ThankYouSocialMedia,
  ThankYouTitleContainer,
} from "./ThankYou.styles";
//Images
import { SocialMediaIcons } from "../../../../assets/svg/socialMedia";
import AppRotate from "./img/AppRotate.webp";
//Components
import { SocialMedia } from "../../../shared/socialMedia/SocialMedia";
import { TrustedBlock } from "../../../shared/trustedBlock/TrustedBlock";
//Utils
import { pathWithUtm } from "../../../../utils/pathWithUtm";

const ThankYou = () => {
  const notMobile = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  return (
    <>
      <ThankYouTitleContainer>
        <SectionContainer>
          <Typography variant="h1" zIndex={10}>
            <b>CONGRATULATIONS!</b> <br /> WE GOT YOUR REQUEST
          </Typography>
          <Typography variant="body2" mb={"10.5rem"} maxWidth={"50rem !important"}>
            Our team is excited to talk with you. You’ll hear from us soon about all the details.
          </Typography>
          <JoinDiscordButton startIcon={SocialMediaIcons.discord}>
            <a href={pathWithUtm("https://discord.gg/mpfxJhA9nM")} target="discord">
              {" "}
              Meet our Discord {notMobile && `community`}
            </a>
          </JoinDiscordButton>
          <ThankYouSocialMedia>
            <Typography>and also stay tuned with:</Typography>
            <SocialMedia />
          </ThankYouSocialMedia>
          <ThankYouImageBox>
            <img src={AppRotate} alt="app" />
          </ThankYouImageBox>
        </SectionContainer>
        <ThankYouLowerMobileBackground />
      </ThankYouTitleContainer>
      <TrustedBlock />
    </>
  );
};

export default ThankYou;
