import Hero from "./hero/Hero";
import Advantages from "./advantages/Advantages";
import Media from "./media/Media";
import Partners from "./partners/Partners";
import ForWhom from "./forwhom/ForWhom";
import Promote from "./promote/Promote";
import Features from "./features/Features";
import Roadmap from "./roadmap/Roadmap";
import Numbers from "./numbers/Numbers";
import Pixel from "./pixel/Pixel";
import { useContext, useEffect } from "react";
import { BrandInfoContext } from "../../../context/BrandInfoContext";
import BrandAnalysis from "../../shared/brandAnalysis/BrandAnalysis";

const Homepage = () => {
  const { setUrl } = useContext(BrandInfoContext);

  useEffect(() => {
    setUrl("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Hero />
      <ForWhom />
      <BrandAnalysis />
      <Promote />
      <Features />
      <Roadmap />
      <Advantages />
      <Numbers />
      <Media />
      <Pixel />
      <Partners />
    </div>
  );
};

export default Homepage;
