import media1 from "./img/media1.webp";
import media2 from "./img/media2.webp";
import media3 from "./img/media3.webp";
export const mediaList = [
  {
    img: media1,
    title: "Taking on the Titans",
    desc: "The vision for Web3m was sparked by my background in cybersecurity and (later) marketing. I was 12 years old when I started hacking. I loved the freedom of information available on the internet…",
    link: "https://sigmamagazine.com/block/winter_spring2023/index.html#issue/15",
  },
  {
    img: media2,
    title: "Introducing Web3m: A Marketing Platform and Community for Crypto\n",
    desc: "We’re excited to introduce you to Web3m, the first decentralized advertising and media planning platform. This tool has been three years in the making…",
    link: "https://blog.web3m.io/introducing-web3m-a-marketing-platform-and-community-for-crypto/",
  },
  {
    img: media3,
    title: "Want better advertising ROI in web3? Start with better data",
    desc: "More than 15 years ago, advertising changed forever with the respective innovations of search advertising and social media advertising. These marketing tools…",
    link: "https://blog.web3m.io/want-better-advertising-roi-in-web3start-with-better-data/",
  },
];
