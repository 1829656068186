import { useContext, useEffect, useState } from "react";
import { BrandInfoContext } from "../../../context/BrandInfoContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useBreakpoints } from "../../../utils/hooks/useBreakpoints";
import { pathWithUtm } from "../../../utils/pathWithUtm";


const defaultBrandSections = {
  brand_description: false,
  general_info: false,
  google_searches: false,
  competitors: false,
  organic_growth: false,
  potential_users: false,
  community_sentiment: false,
}

export const useBrandAnalysis = () => {
  const {
    url,
    setUrlHandler,
    addSectionsHandler,
    brandSectionsList,
    otherSectionsList,
    sendFeedbackHandler,
  } = useContext(BrandInfoContext);

  const navigate = useNavigate();
  const location = useLocation();

  const isBrandInfoPage = location.pathname === '/brand_info';

  const {tabletScreen} = useBreakpoints();

  const [input, setInput] = useState(url || "");
  const [error, setError] = useState("");
  const [openTooltip, setOpenTooltip] = useState(false);
  const [openSectionTooltip, setOpenSectionTooltip] = useState('');
  const [reasonRadio, setReasonRadio] = useState('');
  const [otherSections, setOtherSections] = useState(isBrandInfoPage ? otherSectionsList : []);
  const [brandSections, setBrandSections] = useState(defaultBrandSections);
  const [addSections, setAddSections] = useState([]);
  const [hideMobileInfo, setHideMobileInfo] = useState(tabletScreen && url !== '');

  useEffect(function mount() {
    function onScroll(e) {
      setOpenSectionTooltip('')
    }
    function onTouch(e) {
      setOpenSectionTooltip('')
    }
    window.addEventListener("wheel", onScroll);
    window.addEventListener("touchstart", onTouch);

    return function unMount() {
      window.removeEventListener("wheel", onScroll);
      window.removeEventListener("touchstart", onTouch);
    };
  });

  useEffect(() => {
    url !== '' && setHideMobileInfo(tabletScreen)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[tabletScreen])

  useEffect(() => {
    return () => {
      setBrandSections(defaultBrandSections)
    }
  },[])

  useEffect(() => {
    if (url === '') {
      setBrandSections(defaultBrandSections)
      setReasonRadio('')
    }
  },[url])

  useEffect(() => {
    if (!isBrandInfoPage) {
      setBrandSections(defaultBrandSections)
      setOtherSections([])
      setAddSections([])
      setReasonRadio('')
    } else {
      const sections = defaultBrandSections;
      brandSectionsList.forEach((el) => {sections[el] = true})
      setBrandSections(sections)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[location])

  const handleChangeInput = (e) => {
    setInput(e.target.value);
  };

  const handleBrandSections = (e) => {
    setBrandSections((prevState) => ({...prevState, [e.target.name]: !prevState[e.target.name]}));

    if (isBrandInfoPage && url !== '') {
      // console.log(brandSectionsList);
      if (!brandSections[e.target.name] && !addSections.includes(e.target.name) && !brandSectionsList.includes(e.target.name)) {
        setAddSections((prevState) => ([...prevState, e.target.name]));
      }
      else if (brandSections[e.target.name]) {
        setAddSections((prevState) => (prevState.filter((el) => el !== e.target.name)));
      }
    }
  }

  const handleTooltip = () => {
    setOpenTooltip(!openTooltip);
  }

  const handleTooltipButton = async (other_input) => {
    const response = await sendFeedbackHandler({"others": other_input, "brand_url": input})
    if (response) {
      setOtherSections([...otherSections, other_input])
    } else {
      setError("Something went wrong! Please try again later!")
    }
    handleTooltip()
  }

  const fetchBrandInfoData = async () => {
    const pattern = /^(https?:\/\/)?(www\.)?[a-zA-Z0-9]+(\.[a-zA-Z]{2,}){1,}\/?.*$/;
    const isValidLink = pattern.test(input);

    if (isValidLink) {
      try {
        const brand_sections = Object.entries(brandSections).filter(([_, value]) => value).map(([key, _]) => key);
        if (isBrandInfoPage && url !== '') {
          await addSectionsHandler(addSections)
        } else {
          setUrlHandler(input, brand_sections, otherSections, reasonRadio)
        }
        setAddSections([])
      } catch (err) {
        setError("Could not fetch the data!");
      }
      reasonRadio === 'bi' && location.pathname !== "/brand_info" && navigate(pathWithUtm("/brand_info"));
    } else {
      setError("Your url is not valid! Please try again!");
    }
  };

  // Update local state while navigating from brand info to homepage
  useEffect(() => {
    setInput(url);
  }, [url]);



  const buttonRunDisabled =
    (!isBrandInfoPage && url === input.trim()) ||
    (!isBrandInfoPage && reasonRadio === "") ||
    (isBrandInfoPage && url === '' && reasonRadio === "") ||
    Object.values(brandSections).filter(el => el).length === 0 ||
    (isBrandInfoPage && url !== '' && addSections.length === 0) ||
    (isBrandInfoPage && url === '' && input.trim() === '');


  return {
    input,
    handleChangeInput,
    fetchBrandInfoData,
    error,
    buttonRunDisabled,
    brandSections,
    otherSections,
    reasonRadio,
    setReasonRadio,
    handleBrandSections,
    openTooltip,
    handleTooltip,
    handleTooltipButton,
    isBrandInfoPage,
    hideMobileInfo,
    setHideMobileInfo,
    openSectionTooltip,
    setOpenSectionTooltip,
  };
};
