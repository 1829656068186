export const pages = [
  {
    name: "Home",
    link: "/",
    list: false,
    disabled: false,
  },
  {
    name: "Ecosystem",
    link: "/ecosystem",
    list: false,
    disabled: false,
  },
  {
    name: "Solutions",
    link: null,
    list: true,
    disabled: false,
    menu: [
      {
        name: "Pixel",
        link: "/pixel",
      },
      { name: "Data Network", link: "/data_network" },
      { name: "Ad Network", link: "/ad_network" },
      // {
      //   name: "Airdrop",
      //   link: "/airdrop"
      // }
    ],
  },
  // {
  //   name: "About us",
  //   link: null,
  //   list: true,
  //   disabled: true,
  //   menu: [
  //     {
  //
  //     }]
  // },
  {
    name: "Membership",
    link: "/membership",
    list: false,
    disabled: false,
  },
  {
    name: "Our community",
    link: "https://discord.com/invite/7NFVRymVZf",
    list: false,
    disabled: false,
  },
  {
    name: "FAQ",
    link: "/faq",
    list: false,
    disabled: false,
  },
  {
    name: "Blog",
    link: "https://blog.web3m.io/",
    list: false,
    disabled: false,
  },
  // {
  //   name: "Roadmap",
  //   link: "/roadmap",
  //   list: false,
  //   disabled: false,
  // },
  // {
  //   name: "Request Demo",
  //   link: "/signup",
  //   list: false,
  //   disabled: false,
  // },
];
