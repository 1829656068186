//Core
import React from "react";
import { Link } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
//Styles
import {
  BurgerButton,
  CreateCampaignButton,
  HeaderContainer,
  HeaderNavBox,
  HeaderNavItem,
  LoginButton,
  RequestDemoButton,
} from "./Header.styles";
import { Drawer, List, Toolbar, Typography } from "@mui/material";
import { CustomTooltip } from "./tooltip/AttentionTooltip.styles";
//Components
import { ButtonHeader } from "./buttonHeader/buttonHeader";
import MobileMenuNew from "./mobileMenu/MobileMenu";
import AttentionTooltip from "./tooltip/AttentionTooltip";
import LoginPopup from "../../shared/popup/login/LoginPopup";
//Hooks
import { useHeader } from "./data/useHeader";
import { useAuth } from "./data/useAuth";
// import { useLoginWithRedirect } from "../../../utils/hooks/useLoginWithRedirect";
//Images
import { Logo } from "../../../assets/svg/logo";
import { AttentionUserSvg } from "../../../assets/svg/icons";
//Data
import { pages } from "./data/navigations";
//Utils
import { dashboardLink } from "../../../utils/helpers";
import { pathWithUtm } from "../../../utils/pathWithUtm";

export const Header = () => {
  const {
    faqPage,
    location,
    menuOpen,
    toggleMobileMenu,
    headerBackground,
    headerNavBoxClassName,
    hasNFT,
    logout,
    createCampaignButton,
    openLoginPopup,
  } = useHeader();
  // const { onLogin } = useLoginWithRedirect();
  const { isAuthenticated, isLoading } = useAuth0();
  useAuth();

  const navigateToSignUp = () => {
    window.open("https://registration.web3m.io/", "_blank");
  };

  return (
    <>
      <HeaderContainer elevation={0} sx={headerBackground}>
        <Toolbar>
          <HeaderNavBox className={headerNavBoxClassName || ""}>
            <Link to={pathWithUtm("/")}>
              <Logo />
            </Link>
            <List sx={{ display: "flex", marginLeft: "1.5rem" }}>
              {pages.map((page, i) => (
                <HeaderNavItem key={page.name + i} className={location.pathname === page.link ? "active" : ""}>
                  {ButtonHeader(page)}
                </HeaderNavItem>
              ))}
              {!isAuthenticated && !isLoading && (
                <>
                  <HeaderNavItem>
                    <LoginButton className={headerNavBoxClassName || ""} onClick={() => navigateToSignUp()}>
                      Sign Up
                    </LoginButton>
                  </HeaderNavItem>
                  <HeaderNavItem>
                    <LoginButton className={headerNavBoxClassName || ""} onClick={() => openLoginPopup("signIn")}>
                      Login
                    </LoginButton>
                  </HeaderNavItem>
                </>
              )}

              {isAuthenticated && (
                <>
                  <HeaderNavItem>
                    <RequestDemoButton variant="outlined" onClick={logout}>
                      <Typography variant="body2">{"Logout"}</Typography>
                    </RequestDemoButton>
                  </HeaderNavItem>
                  <CustomTooltip title={!hasNFT ? <AttentionTooltip /> : ""} arrow placement={"bottom-end"}>
                    <HeaderNavItem>
                      <a href={pathWithUtm(`${dashboardLink}settings`)}>
                        <LoginButton className={headerNavBoxClassName || ""}>
                          {!hasNFT && <AttentionUserSvg width={"2rem"} style={{ marginRight: ".5rem" }} />}
                          {"User Account"}
                        </LoginButton>
                      </a>
                    </HeaderNavItem>
                  </CustomTooltip>
                </>
              )}
            </List>

            <CreateCampaignButton className={"create-campaign"} onClick={createCampaignButton}>
              Create campaign
            </CreateCampaignButton>
            <BurgerButton onClick={toggleMobileMenu} className={headerNavBoxClassName + (faqPage ? " dark" : "")}>
              <b />
              <b />
              <b />
            </BurgerButton>
          </HeaderNavBox>
        </Toolbar>
      </HeaderContainer>
      <Drawer open={menuOpen} anchor="right">
        <MobileMenuNew toggleMobileMenu={toggleMobileMenu} />
      </Drawer>
      <LoginPopup />
    </>
  );
};

export default Header;
