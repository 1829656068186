import { Box, Stack, styled } from "@mui/material";
import { mobileScreen, smallMobileScreen, tabletOnlyScreen } from "../../../../utils/themeBreakpoints";
import theme from "../../../../styles/theme";

export const HeroBackground = styled(Box)`
  //background-image: linear-gradient(282deg, #fff 20%, #0f143f, #5393e3);
  //background-color: #1264da;
  //background-image: linear-gradient(to top, #1264da, #106eed);
  //background-image: linear-gradient(270deg, #0F143F 17%, #0E75FC 100%);
  position: absolute;
  height: 100%;
  width: 100%;
  //border-bottom-right-radius: 60%;

  > svg {
    position: absolute;
    height: 100%;
    right: 0;
    ${mobileScreen} {
      display: none;
    }
  }

  ${mobileScreen} {
    background-image: linear-gradient(320deg, #0f143f, #0e75fc);
    border-radius: 0 0 10% 10%;
  }

  ${smallMobileScreen} {
    border-radius: 0 0 7% 7%;
  }
`;

export const HeroBackgoundExtension = styled(Box)`
  position: absolute;
  left: 0;
  width: 70vw;
  height: 100%;
  background-image: linear-gradient(to top, #1264da, #106eed);

  @media only screen and (max-width: 2100px) {
    width: 10vw;
  }
  @media only screen and (max-width: 1536px) {
    display: none;
  }
`;

export const HeroContent = styled(Stack)`
  max-width: 70rem;
  width: 60%;
  align-self: flex-start;
  //margin-right: 3rem;

  h1 {
    margin-bottom: 5.3rem;
    padding-top: 10rem;
    b {
      color: #53baff;
    }
  }

  p {
    width: 80%;
    margin-bottom: 4.6rem;

    ${mobileScreen} {
      margin-bottom: 9rem;
    }
  }

  button {
    border-radius: 0.72rem;
    padding: 1.5rem 3.5rem;
  }

  p,
  .MuiListItemText-root > span {
    color: #fff;
    line-height: 1.25;
    text-shadow: 0 2px 4px rgba(15, 22, 67, 0.65);
  }

  .MuiList-root {
    margin-bottom: 14rem;
    ${mobileScreen} {
      width: fit-content;
      margin: 0 auto 20rem;
    }
    ${smallMobileScreen} {
      margin: 0 auto 14rem;
    }
  }

  .MuiListItem-root {
    padding-left: 0;

    ${smallMobileScreen} {
      padding-right: 0;
    }
  }

  .MuiListItemIcon-root {
    min-width: auto;
    margin-right: 1rem;
  }

  a {
    button {
      ${tabletOnlyScreen} {
        transform: translateY(-200%);
      }
    }
  }

  ${mobileScreen} {
    margin: 0 auto;
    width: 90%;
  }

  ${theme.breakpoints.down("sm")} {
    width: 100%;
  }

  ${smallMobileScreen} {
    //width: 100%;
    h1 {
      font-size: 5rem;
      //text-align: center;
    }
    p {
      text-align: center;
      width: 100%;
      margin: 0 auto 5rem;
    }
  }
`;

export const HeroImageContainer = styled(Box)`
  width: 50%;
  transform: translate(3rem, 22rem) scale(1.4);
  transform-origin: bottom left;

  > img {
    width: 100%;
  }

  ${mobileScreen} {
    margin-top: -25%;
    width: 100%;
    transform-origin: center;
    transform: translate(5.5%, 26%) scale(1.25);
  }

  ${smallMobileScreen} {
    margin: 0;
    transform: translate(5.5%, 24%) scale(1.25);
  }

  ${tabletOnlyScreen} {
    margin-top: -10rem;
    //width: 70%;
    max-width: 80rem;
  }
`;
