import { Box, Typography } from "@mui/material";
import { PartnerCard, PartnerCardList, PartnersBackground } from "./Partners.styles";
import { ImgBox, SectionContainer } from "../../../../styles/components/Layout";
import { partnersList } from "./Partners.data";
import { SecondaryTitle } from "../../../../styles/components/typography";

const Partners = () => {
  return (
    <Box sx={{ position: "relative" }}>
      <PartnersBackground />
      <SectionContainer sx={{ p: "15rem 0" }}>
        <SecondaryTitle color={"#fff"} mb={"15rem"}>
          Our partners
        </SecondaryTitle>
        <PartnerCardList>
          {partnersList?.map((el) => (
            <PartnerCard key={el.company}>
              <ImgBox>
                <img src={el.img} style={{ filter: "grayscale(100%)" }} alt={'partners'} />
              </ImgBox>
              <Typography>{el.company}</Typography>
            </PartnerCard>
          ))}
        </PartnerCardList>
      </SectionContainer>
    </Box>
  );
};

export default Partners;
