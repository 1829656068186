import step1 from "./img/step1.webp";
import step2 from "./img/step2.webp";
import step3 from "./img/step3.webp";

export const stepsList = [
  {
    img: step1,
    text: "Gain early access to the platform through the Web3m NFT.",
  },
  {
    img: step2,
    text: "Use the Web3m dashboard to create your first ad.",
  },
  {
    img: step3,
    text: "Publish your ad across thousands of popular web3 sites, blogs, and platforms.",
  },
];
