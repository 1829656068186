//Core
import { Link } from "react-router-dom";
//Styles
import { FlexResponsive, ImgBox, SectionContainer } from "../../../../styles/components/Layout";
import { Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { StepContainer, StepNumber, StepsButtons } from "./Steps.styles";
import { SecondaryTitle } from "../../../../styles/components/typography";
import { MainButton } from "../../../../styles/components/buttons";
//Hooks
import { useLoginWithRedirect } from "../../../../utils/hooks/useLoginWithRedirect";
//Utils
import { textAlignResponsive } from "../../../../utils/cssHelpers";
import { stepsList } from "./Steps.data";
import { pathWithUtm } from "../../../../utils/pathWithUtm";

const Steps = () => {
  const {onLogin} = useLoginWithRedirect();

  return (
    <SectionContainer sx={{ p: "10rem 0" }}>
      <SecondaryTitle mb={"7rem"}>
        Make better data-driven decisions. <br />
        Monetize with the Web3m data network
      </SecondaryTitle>
      <Typography variant={"subtitle1"} color={"#000"} mb={"10rem"} textAlign={textAlignResponsive}>
        You’re 3 steps from creating your first web3 ad:
      </Typography>
      <Grid2 container spacing={"10rem"} mb={"10rem"}>
        {stepsList?.map((el, i) => (
          <Grid2 xs={12} md={4} key={el.text}>
            <StepContainer>
              <ImgBox>
                <img src={el.img} alt={'steps'}/>
              </ImgBox>
              <FlexResponsive>
                <StepNumber>
                  <span>{i + 1}</span>
                </StepNumber>

                <Typography>{el.text}</Typography>
              </FlexResponsive>
            </StepContainer>
          </Grid2>
        ))}
      </Grid2>
      <StepsButtons>
        <Link to={pathWithUtm("/membership")}>
          <MainButton>I WANT TO BECOME A MEMBER</MainButton>
        </Link>
        {/*<Link to={pathWithUtm("/signup")}>*/}
          <MainButton className={'light'} onClick={onLogin}>
            {"CREATE MY FIRST AD"}
          </MainButton>
        {/*</Link>*/}
      </StepsButtons>
    </SectionContainer>
  );
};

export default Steps;
