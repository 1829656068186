//Core
import React from "react";
import HttpsRedirect from "react-https-redirect";
import { Auth0Provider } from "@auth0/auth0-react";
//Components
import { Main } from "./components/layout/Main";
import { Snack } from "./components/layout/snack/Snack";
//Styles
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "./styles/theme";
//Router
import { BrowserRouter } from "react-router-dom";
//Context
import BrandInfoProvider from "./context/BrandInfoProvider";
//Redux
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { SnackbarProvider } from "notistack";

function App() {

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <HttpsRedirect>
            <Auth0Provider
              domain={process.env.REACT_APP_AUTH0_DOMAIN}
              clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
              audience="https://dev-s5y764on.us.auth0.com/api/v2/"
              scope="read:current_user update:current_user_metadata"
              useRefreshTokens
              redirectUri={window?.location.origin + window.location.search}
              // onRedirectCallback={onRedirectCallback}
              // cacheLocation="localstorage"
            >
              <SnackbarProvider
                maxSnack={5}
                style={{fontSize: "1.6rem"}}
                hideIconVariant
                autoHideDuration={10000}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                preventDuplicate
              >
                <BrandInfoProvider>
                  <Main />
                  <Snack />
                </BrandInfoProvider>
              </SnackbarProvider>
            </Auth0Provider>
          </HttpsRedirect>
        </BrowserRouter>
        <CssBaseline />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
