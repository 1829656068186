import { useEffect, useState } from "react";
import {
    getBrandProfile,
    getGeneralInfo,
    getGeneralTwitterInfo,
    getKeywords,
    getSocialScraper
} from "../services/gatewayService";
import { getCompetitors } from "../services/web3mBetaService";
import { fetchItems } from "../utils/fetchItems";
import { sendFeedback, sendNoContent } from "../services/gatewayServiceStats";
import { coPilotLink } from "../utils/helpers";
import { useLocation } from "react-router-dom";
import { useBeforeunload } from "react-beforeunload";
import { useSnack } from "../utils/useSnack/useSnack";
import { sectionOpenItems } from "../components/shared/brandAnalysis/help/sectionLabel";
import { snackMessages } from "../utils/useSnack/snackMessages";
import { pathWithUtm } from "../utils/pathWithUtm";

export const useContextData = () => {
    const location = useLocation();
    const snack = useSnack();

    const [fetchList, setFetchList] = useState([]);
    const [url, setUrl] = useState("");
    const [brandProfile, setBrandProfile] = useState(null);
    const [generalInfo, setGeneralInfo] = useState(null);
    const [generalTwitterInfo, setGeneralTwitterInfo] = useState(null);
    const [keywords, setKeywords] = useState(null);
    const [competitors, setCompetitors] = useState(null);
    const [socialMedia, setSocialMedia] = useState(null);
    const [brandSectionsList, setBrandSectionsList] = useState([]);
    const [otherSectionsList, setOtherSectionsList] = useState([]);
    const [time, setTime] = useState(0);

    const [sectionOpen, setSectionOpen] = useState('');

    const sectionData = {
        brand_description: brandProfile,
        general_info: generalInfo,
        google_searches: keywords,
        competitors: competitors,
        organic_growth: generalTwitterInfo,
        potential_users: socialMedia,
        community_sentiment: generalTwitterInfo,
    }

    const startFetching = (payload) => {
        setFetchList((prev) => [...prev, payload]);
    }

    const stopFetching = (payload) => {
        setFetchList((prev) => prev.filter((el) => el !== payload));
    }

    const clearData = () => {
        setBrandProfile(null);
        setGeneralInfo(null);
        setGeneralTwitterInfo(null);
        setKeywords(null);
        setCompetitors(null);
        setSocialMedia(null);
    }

    const fetchGeneralInfo = async (url, linkedin, brandSections) => {
        startFetching(fetchItems.general_info)
        const generalInfo = await getGeneralInfo(url, linkedin?.link || null)
        // console.log('general_info', generalInfo);
        if (generalInfo) setGeneralInfo(generalInfo)
        else {
            if (brandSections.includes(sectionOpenItems.general_info)) {
                snack(snackMessages.general_info, "error")
                void sendNoContent(sectionOpenItems.general_info)
            }
        }
        stopFetching(fetchItems.general_info)
    }

    const fetchGeneralTwitterInfo = async (twitter, brandSections) => {
        if (twitter) {
            startFetching(fetchItems.general_twitter_info)
            const generalTwitterInfo = await getGeneralTwitterInfo(twitter.link)
            // console.log('general_twitter_info', generalTwitterInfo);
            if (generalTwitterInfo) setGeneralTwitterInfo(generalTwitterInfo);
            else {
                if (brandSections.includes(sectionOpenItems.organic_growth)) {
                    snack(snackMessages.organic_growth, "error");
                    void sendNoContent(sectionOpenItems.organic_growth);
                }
                if (brandSections.includes(sectionOpenItems.community_sentiment)) {
                    snack(snackMessages.community_sentiment, "error");
                    void sendNoContent(sectionOpenItems.community_sentiment);
                }
            }
            stopFetching(fetchItems.general_twitter_info)
        }
    }

    const fetchSocialScraper = async (url, media, brandSections) => {
        if (media) {
            startFetching(fetchItems.social_scraper)
            const socialScraper = await getSocialScraper(url, media)
            // console.log('social_scraper', socialScraper);
            if (socialScraper) setSocialMedia(socialScraper)
            else {
                if (brandSections.includes(sectionOpenItems.potential_users)) {
                    snack(snackMessages.potential_users, "error")
                    void sendNoContent(sectionOpenItems.potential_users)
                }
            }
            stopFetching(fetchItems.social_scraper)
        }
    }

    const fetchKeywordsSearches = async (url, linkedin, brandData, brandSections) => {
        const keywordsData = {
            url,
            linkedin: linkedin?.link || null,
            description: brandData?.description || "",
            extra_text: brandData?.extra_text || []
        }
        startFetching(fetchItems.keywords_searches)
        const keywords = await getKeywords(keywordsData)
        // console.log('keywords', keywords);
        if (keywords) setKeywords(keywords)
        else {
            if (brandSections.includes(sectionOpenItems.google_searches)) {
                snack(snackMessages.google_searches, "error")
                void sendNoContent(sectionOpenItems.google_searches)
            }
        }
        stopFetching(fetchItems.keywords_searches)
    }

    const fetchCompetitors = async (url, brandData, brandSections) => {
        const brandName = brandData?.name || url?.replace("https://", "").split(".")[0];
        startFetching(fetchItems.competitors)
        const competitors = await getCompetitors(url, brandName)
        // console.log('competitors', competitors);
        if (competitors) setCompetitors(competitors)
        else {
            if (brandSections.includes(sectionOpenItems.competitors)) {
                snack(snackMessages.competitors, "error")
                void sendNoContent(sectionOpenItems.competitors)
            }
        }
        stopFetching(fetchItems.competitors)
    }
    const getData = async (url, brandSections) => {
        clearData();
        //get brand profile
        startFetching(fetchItems.brand_profile)
        const brandData = await getBrandProfile(url);
        stopFetching(fetchItems.brand_profile);

        if (brandData) {
            setBrandProfile(brandData);

            const media = brandData?.social_media;
            const twitter = media?.find((item) => item?.platform_name === 'twitter');
            const linkedin = media?.find((item) => item?.platform_name === 'linkedin');

            await Promise.all([
                fetchGeneralInfo(url, linkedin, brandSections),
                fetchSocialScraper(url, media, brandSections),
                fetchKeywordsSearches(url, linkedin, brandData, brandSections),
                fetchCompetitors(url, brandData, brandSections),
                fetchGeneralTwitterInfo(twitter, brandSections)
            ]);
        }
        else {
            snack(snackMessages.brand_description, "error");
            void sendNoContent(sectionOpenItems.brand_description);
            setUrl("");
            setBrandSectionsList([]);
        }


    }

    const sendFeedbackHandler = async (payload, path) => {
        startFetching(fetchItems.feedback);
        const response = await sendFeedback(payload, path);
        stopFetching(fetchItems.feedback);
        return response;
    }

    const setUrlHandler = async (url, brandSections, otherSections, bi) => {
        setBrandSectionsList(brandSections)
        setOtherSectionsList(otherSections)
        let payload;
        if (bi === 'bi') {
            payload = {
                checked_fields: brandSections,
                brand_url: url,
                is_added_fields: false
            };
            setUrl(url);
            void sendFeedbackHandler(payload)
            await getData(url, brandSections)
        } else {
            payload = {
                checked_fields: brandSections,
                brand_url: url,
                create_campaign_without_BI: true
            };
            await sendFeedbackHandler(payload)
            window.location.replace(pathWithUtm(`${coPilotLink}?utm_campaign=organic&utm_medium=brandinfo`))
        }
    }

    const addSectionsHandler = async (addSections) => {
        const payload = {
            checked_fields: addSections,
            brand_url: url,
            is_added_fields: true
        }
        const response = await sendFeedbackHandler(payload)
        addSections.forEach((section) => {
            if (!sectionData[section]) {
                console.log(section, sectionData[section]);
                snack(snackMessages[section], "error");
                void sendNoContent(sectionOpenItems[section])
            }
        });
        if (response.updated) setBrandSectionsList((prev) => [...prev, ...addSections])
    }

    useEffect(() => {
            const countTimeTimeout = setTimeout(() => {
                sectionOpen !== '' && setTime((prev) => (prev + 0.5));
            }, 500);
        return () => {
            clearTimeout(countTimeTimeout);
        };
    }, [time, sectionOpen]);

    const sendTimeLapFeedback = async () => {
        if (sectionOpen !== '') {
            const payload = {
                item: {
                    name: sectionOpen,
                    value: Number(time.toFixed(1))
                },
                brand_url: url
            }
            await sendFeedbackHandler(payload, fetchItems.feedback_lap)
        }
        setTime(0)

    }

    useEffect(() => {
        if (location.pathname !== '/brand_info') {
            void sendTimeLapFeedback()
            setSectionOpen('')
            setOtherSectionsList([])
            setBrandSectionsList([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    const handleSectionOpen = (section) => {
            void sendTimeLapFeedback()
            if (section !== sectionOpen) {
                setSectionOpen(section)
            } else {
                setSectionOpen('')
            }
    }

    useBeforeunload((ev) => {
        if (sectionOpen !== '') {
            ev.preventDefault();
            console.log('useBeforeunload', sectionOpen);
            void sendTimeLapFeedback()
            ev.returnValue = '';
            return ev.returnValue;
        }
    })

    return {
        fetchList,
        url,
        setUrl,
        setUrlHandler,
        brandProfile,
        generalInfo,
        generalTwitterInfo,
        keywords,
        competitors,
        socialMedia,
        getData,
        brandSectionsList,
        addSectionsHandler,
        otherSectionsList,
        sendFeedbackHandler,
        sectionOpen,
        handleSectionOpen,
        sendTimeLapFeedback,
    }
}