export function CodeIcon(props) {
  return (
    <svg
      viewBox="14 10 30 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <filter
          x="-5.5%"
          y="-11.4%"
          width="111.0%"
          height="122.8%"
          filterUnits="objectBoundingBox"
          id="code-icon-a"
        >
          <feOffset dy={6} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            stdDeviation={12}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0.0568630383 0 0 0 0 0.33553161 0 0 0 0 0.603053438 0 0 0 0.231209708 0"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g
        filter="url(#code-icon-a)"
        transform="translate(-449 -10426) translate(-20 5305.764) translate(484 4789.223)"
        fill="#FFF"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      >
        <path d="M15.922 339.98c.46.115.738.58.623 1.038l-4.572 18.286a.858.858 0 01-1.663-.415l4.572-18.286a.858.858 0 011.04-.624m-8.745 3.654a.857.857 0 010 1.212l-5.109 5.109 5.109 5.107a.857.857 0 01-1.212 1.213L.251 350.56a.857.857 0 010-1.213l5.714-5.714a.856.856 0 011.212 0m12.503 0a.856.856 0 011.211 0l5.714 5.714a.857.857 0 010 1.213l-5.714 5.714a.856.856 0 11-1.211-1.213l5.107-5.107-5.107-5.109a.857.857 0 010-1.212" />
      </g>
    </svg>
  )
}

export function CubeIcon(props) {
  return (
    <svg
      viewBox="15 10 30 30"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <filter
          x="-5.5%"
          y="-11.4%"
          width="111.0%"
          height="122.8%"
          filterUnits="objectBoundingBox"
          id="cube-icon-a"
        >
          <feOffset dy={6} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            stdDeviation={12}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0.0568630383 0 0 0 0 0.33553161 0 0 0 0 0.603053438 0 0 0 0.231209708 0"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g
        filter="url(#cube-icon-a)"
        transform="translate(-448 -10520) translate(-20 5305.764) translate(484 4789.223)"
        fill="#FFF"
        stroke="none"
        strokeWidth={1}
        fillRule="evenodd"
      >
        <path d="M13.273 436.852c.9-.495 1.99-.495 2.89 0l8.72 4.796a2.996 2.996 0 011.553 2.627v9.411c0 1.093-.595 2.1-1.554 2.627l-8.72 4.796c-.9.495-1.99.495-2.889 0l-8.72-4.796A2.998 2.998 0 013 453.686v-9.411a2.997 2.997 0 011.553-2.627l8.72-4.796zm2.102 1.432a1.365 1.365 0 00-1.313 0l-8.13 4.471 8.786 5.272 8.787-5.272-8.13-4.471zm9.377 5.63l-9.217 5.53v10.144l8.56-4.707c.435-.24.706-.698.706-1.195v-9.411c0-.125-.017-.246-.05-.361zM13.9 459.588v-10.144l-9.216-5.531a1.38 1.38 0 00-.05.362v9.411c0 .497.271.955.707 1.195l8.56 4.707z" />
      </g>
    </svg>
  )
}