//Core
import React from "react";
import { Link } from "react-router-dom";
//Styles
import { ListItemText } from "@mui/material";
//Images
import { WalletIcon, WorldIcon } from "../hero/img/Hero.icons";
import { CodeIcon, CubeIcon } from "./img/Pixel.icons";
//Utils
import { pathWithUtm } from "../../../../utils/pathWithUtm";

export const pixelList = [
  {
    icon: CodeIcon(),
    text: <ListItemText>1. Go to the <Link to={pathWithUtm('/pixel')}> pixel page </Link></ListItemText>,
  },
  {
    icon: WorldIcon(),
    text: <ListItemText>2. Follow the instructions process</ListItemText>,
  },
  {
    icon: CubeIcon(),
    text: <ListItemText>3. Share anonymous data with the Web3m network</ListItemText>,
  },
  {
    icon: WalletIcon(),
    text: <ListItemText>4. Receive an airdrop later this year </ListItemText>,
  },
];
