import tasksImg from "../../../../assets/img/cards/tasks.webp";
import deliveryImg from "../../../../assets/img/cards/delivery.webp";
import configImg from "../../../../assets/img/cards/config.webp"
import budgetImg from "../../../../assets/img/cards/budget.webp"
import loudspeakerImg from "../../../../assets/img/cards/loudspeaker.webp"
import securityImg from "../../../../assets/img/cards/security.webp"

export const featuresList = [
  {
    title: "Perform Brand & Market analysis",
    desc: "Just enter your website and get a full profile and metrics about your brand and competition",
    img: tasksImg,
  },
  {
    title: "It's easier to focus on your target audience",
    desc: "Generate tailored audience personas and choose who you really want to reach based on your goals",
    img: deliveryImg,
  },
  {
    title: "Measure real-time ad performance",
    desc: "See the results from all channels in one analytics dashboard, and easily scale from there",
    img: configImg,
  },
  {
    title: "Reach your customers where they at",
    desc: "Advertise across the biggest PPC optimized websites and exchanges in crypto",
    img: budgetImg,
  },
  {
    title: "Launch on-demand influencer campaigns",
    desc: "Partner with web3 influencers for powerful social campaigns",
    img: loudspeakerImg,
  },
  {
    title: "Appear in the media with PR services",
    desc: "Send press releases across the wire and partner with on-demand PR agencies",
    img: securityImg,
  },
];
